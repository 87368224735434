.widget-page {
  background-color: #f0f0f0;
  height: 100%;
  padding: 20px;
  position: relative;
}

.widget-page .vehicle-display {
  padding: 20px;
}
